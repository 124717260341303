import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import { TextField, useForm } from '../../components/FormInputs';
import { Normal } from '../../components/Button';

// Helper Functions
import {
  fullName,
  noBlankSpaces,
} from '../../components/FormInputs/helperFunctions';
import { updateAssetMetatdataService } from '../duck/operations';
import { enqueueSnackbar } from '../../snackbar/duck/actions';

// Constants
import { VALIDATION_MESSAGES } from '../../constants';

// Styles
import {
  StyledPaper,
  MaterialAutocompleteStyles,
} from '../../components/FormInputs/autoComplete';
import { Text, ListKeyValueItemStyles } from '../../styles/common';
import { Body, Footer } from '../../styles/sidebar';
import { whiteAlpha } from '../../styles/utils';
import { colors } from '../../styles/variables';
import { formatDateTime } from '../../helperFunctions';

const INITIAL_STATE = {
  name: {
    value: '',
    error: undefined,
    required: false,
    validation: value => {
      if (!fullName(value)) return VALIDATION_MESSAGES.noSpecialCharacters;
      if (value) return noBlankSpaces(value);
      return undefined;
    },
  },
  keywords: {
    value: [],
    error: undefined,
  },
};

const Metadata = props => {
  const dispatch = useDispatch();
  const { closeSidebar, details } = props;

  const { project_db_id } = useParams();

  const { formValues, onChange, onSubmit, updateValues } = useForm(
    INITIAL_STATE,
    async () => {
      const keywords = formValues?.keywords?.value?.map(el => el.value) ?? [];

      try {
        const { data } = await updateAssetMetatdataService(
          {
            project_db_id,
            ingest_db_id: details?.ingest_db_id,
            keywords,
            additional_filename: formValues?.name?.value,
          },
          'post'
        );

        if (data?.response) {
          closeSidebar();
          dispatch(
            enqueueSnackbar({
              message: {
                messageHead: 'Success',
                messageBody: data?.data?.message,
                variant: 'success',
              },
            })
          );
        } else throw Error(data?.errormessage);
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          })
        );
      }
    }
  );

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const { data } = await updateAssetMetatdataService({
          project_db_id,
          ingest_db_id: details?.ingest_db_id,
        });

        if (data?.response) {
          const options = data?.data?.keywords?.map(el => ({
            label: el,
            value: el,
          }));
          updateValues({
            ...formValues,
            keywords: {
              ...formValues.keywords,
              value: options,
            },
            name: {
              ...formValues.name,
              value: data?.data?.additional_filename ?? '',
            },
          });
        } else throw Error(data?.errormessage);
      } catch (err) {
        dispatch(
          enqueueSnackbar({
            message: {
              messageHead: 'Error',
              messageBody: err?.message,
              variant: 'error',
            },
          })
        );
      }
    };

    getMetadata();
  }, []);

  const onKeywordChange = (e, option, reason) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      const charactersLength = option?.reduce((acc, curr) => {
        // eslint-disable-next-line no-param-reassign
        acc += curr?.value?.length;
        return acc;
      }, 0);

      updateValues({
        ...formValues,
        keywords: {
          ...formValues?.keywords,
          value: option,
          error:
            charactersLength > 100
              ? 'Keywords max character limit is 100'
              : undefined,
        },
      });
    }

    if (reason === 'clear') {
      updateValues({
        ...formValues,
        keywords: {
          value: [],
          error: undefined,
        },
      });
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} name='name-keywords-form'>
        <Body>
          <div aria-label='asset-metadata'>
            <ul
              className='grid gap-y-1-point-5'
              style={{ gridTemplateColumns: 'repeat(2,minmax(0,1fr))' }}>
              <ListKeyValueItemStyles
                aria-label='uploaded-by'
                style={{ gridColumn: 'span 2' }}>
                <p>Uploaded By</p>
                <h4>{details?.uploaded_by}</h4>
              </ListKeyValueItemStyles>

              <ListKeyValueItemStyles aria-label='ingest-id'>
                <p>Upload Id</p>
                <h4>{details?.ingest_id}</h4>
              </ListKeyValueItemStyles>

              <ListKeyValueItemStyles aria-label='uploaded-on'>
                <p>Uploaded On</p>
                <h4>{`${formatDateTime(details?.uploaded_date).date} | ${
                  formatDateTime(details?.uploaded_date).time
                }`}</h4>
              </ListKeyValueItemStyles>

              <ListKeyValueItemStyles aria-label='uploaded-from'>
                <p>Uploaded From</p>
                <h4>{details?.uploaded_from_text}</h4>
              </ListKeyValueItemStyles>

              <ListKeyValueItemStyles aria-label='uploaded-from'>
                <p>Format</p>
                <h4 style={{ textTransform: 'uppercase' }}>
                  {details?.format}
                </h4>
              </ListKeyValueItemStyles>
            </ul>
          </div>

          <Text color={whiteAlpha(0.6)} className='px-0 mt-1-5' md>
            Add or update asset metadata
          </Text>

          <TextField
            id='name'
            label='Additonal Name'
            value={formValues.name.value}
            onChange={onChange}
            error={!!formValues.name.error}
            helperText={formValues.name.error}
          />

          <div>
            <MaterialAutocompleteStyles
              id='keywords'
              multiple
              autoHighlight
              freeSolo
              disableClearable
              onChange={onKeywordChange}
              options={[]}
              value={formValues.keywords?.value}
              getOptionLabel={option => option?.value ?? ''}
              filterOptions={(options, params) => {
                const filtered = [];
                if (params?.inputValue) {
                  filtered.push({
                    label: `Add "${params?.inputValue}"`,
                    value: params?.inputValue,
                  });
                }
                return filtered;
              }}
              PaperComponent={paperProps => <StyledPaper {...paperProps} />}
              renderInput={fieldProps => (
                <TextField
                  {...fieldProps}
                  fullWidth
                  label='Keywords'
                  error={!!formValues.keywords.error}
                  helperText={formValues.keywords.error}
                />
              )}
              renderOption={option => {
                return (
                  <Text color={colors.black.default} padding='0'>
                    {option?.label}
                  </Text>
                );
              }}
            />
            {!formValues?.keywords?.error && (
              <Text sm color={whiteAlpha(0.6)} padding='0.2rem 0'>
                Type and press enter/return key to make a keyword
              </Text>
            )}
          </div>
        </Body>

        <Footer>
          <Normal
            type='submit'
            disabled={
              !!formValues?.name?.error ||
              !!formValues?.keywords?.error ||
              (!formValues?.keywords?.value?.length && !formValues?.name?.value)
            }>
            Submit
          </Normal>
        </Footer>
      </form>
    </>
  );
};

Metadata.propTypes = {
  closeSidebar: PropTypes.func,
  details: PropTypes.instanceOf(Object),
};

export default Metadata;
